import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

function HeroBanner2({isOpen , setIsOpen , setIsDropdownOpen , isDropdownOpen}) {
  

  const handleToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
    if (window.innerWidth <= 768) {
       setIsOpen(false)
    }
   
  };
  const options = {
    items: 1,
    nav: false,
    dots: false,
    autoplay: true,
    loop: true,
  };

  const slides = [
    {
      bgImg: "assets/images/bg/1.jpg",
      title: "New Arrival",
      subtitle: "Trendy Fashion Sale",
      button: "Shop Now",
    },
    {
      bgImg: "assets/images/bg/2.jpg",
      title: "Simplify Everything",
      subtitle: "",
      button: "Shop Now",
    },
    {
      bgImg: "assets/images/bg/3.JPG",
      title: "Simplify Everything",
      subtitle: "",
      button: "Shop Now",
    },
  ];

  return (
    <div>
      <section className="banner pos-r p-0">
        <div className="px-lg-8">
          <Row>
            <Col lg={3}>
              <div className="navbar navbar-expand-lg navbar-light categories d-block shadow-sm">
                <button
                  className="navbar-toggler d-flex align-items-center text-uppercase"
                  type="button"
                  aria-controls="categoriesDropdown"
                  aria-expanded={isDropdownOpen}
                  aria-label="Toggle navigation"
                  onClick={handleToggle}
                >
                  <i className="las la-stream"></i>Accessories
                </button>

                {/* Responsive dropdown */}
                <div
                  className={`collapse navbar-collapse ${
                    isDropdownOpen ? "show" : ""
                  } d-lg-none`}
                  id="categoriesDropdown"
                >
                  <ul className="navbar-nav d-block w-100">
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Trimmings for Clothes
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Trimmings for Abaya
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Patches
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Dantiel
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Gubier
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Reel Feather
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Reel and Artificial Fur
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Buttons (Metal and Polyester)
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Belts
                      </Link>
                    </li>

                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Printed Fabric
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Evening Fabric Dress
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Handmade Patches
                      </Link>
                    </li>
                    <li className="nav-item">
                      {" "}
                      <Link className="nav-link" to="#">
                        Crystal Stones
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col lg="9">
              <OwlCarousel
                className="banner-slider owl-carousel no-pb h-100"
                {...options}
                navText={[
                  "<span class='las la-arrow-left'><span></span></span>",
                  "<span class='las la-arrow-right'><span></span></span>",
                ]}
              >
                {slides.map((slide, index) => (
                  <div
                    key={index}
                    className="item"
                    style={{
                      backgroundImage: `url(${slide.bgImg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  >
                    <div className="container h-100">
                      <div className="row h-100 align-items-center">
                        <div className="col pl-lg-8 py-11 py-lg-0">
                          <h6 className="letter-space-1 animated3">
                            {slide.subtitle}
                          </h6>
                          <h1 className="mb-4 animated3 text-white line-h-1">
                            {slide.title}
                          </h1>
                          <div className="animated3">
                            <Link className="btn btn-dark" to="#">
                              {slide.button}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}

export default HeroBanner2;
