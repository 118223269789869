export default [
    {
        "id": 1,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3557.JPG", "product/main/ChamaaModaProduct/036A3557.JPG"],
        "stock": 32,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "",
        "rating": 5,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Red"]
    },
    {
        "id": 2,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3566.JPG", "product/main/ChamaaModaProduct/036A3566.JPG"],
        "stock": 32,
        "price": 2400,
        "discount": 600,
        "salePrice": 1800,
        "description": "",
        "rating": 5,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Purple"]
    },
    {
        "id": 3,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3570.JPG", "product/main/ChamaaModaProduct/036A3570.JPG"],
        "stock": 64,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "",
        "rating": 5,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Gold"]
    },
    {
        "id": 4,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3572.JPG", "product/main/ChamaaModaProduct/036A3572.JPG"],
        "stock": 18,
        "price": 3200,
        "discount": 600,
        "salePrice": 2600,
        "description": "",
        "rating": 5,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["White"]
    },
    {
        "id": 5,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3575.JPG", "product/main/ChamaaModaProduct/036A3575.JPG"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "",
        "rating": 3,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Purple"]
    },

    {
        "id": 6,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3579.JPG", "product/main/ChamaaModaProduct/036A3579.JPG"],
        "stock": 18,
        "price": 3200,
        "discount": 600,
        "salePrice": 2600,
        "description": "",
        "rating": 5,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Purple"]
    },
    {
        "id": 7,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3581.JPG", "product/main/ChamaaModaProduct/036A3581.JPG"],
        "stock": 18,
        "price": 1800,
        "discount": 600,
        "salePrice": 1200,
        "description": "",
        "rating": 5,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Black"]
    },
    {
        "id": 8,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3582.JPG", "product/main/ChamaaModaProduct/036A3582.JPG"],
        "stock": 18,
        "price": 2600,
        "discount": 600,
        "salePrice": 2200,
        "description": "",
        "rating": 5,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Red"]
    },
    {
        "id": 9,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3585.JPG", "product/main/ChamaaModaProduct/036A3585.JPG"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "",
        "rating": 5,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Black"]
    },
    {
        "id": 10,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3589.JPG", "product/main/ChamaaModaProduct/036A3589.JPG"],
        "stock": 18,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "",
        "rating": 5,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Orange"]
    },
    {
        "id": 11,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3593.JPG", "product/main/ChamaaModaProduct/036A3593.JPG"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "",
        "rating": 5,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["White"]
    },
    {
        "id": 12,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3596.JPG", "product/main/ChamaaModaProduct/036A3596.JPG"],
        "stock": 18,
        "price": 2400,
        "discount": 600,
        "salePrice": 1800,
        "description": "",
        "rating": 5,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Black"]
    },
    {
        "id": 13,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3598.JPG", "product/main/ChamaaModaProduct/036A3598.JPG"],
        "stock": 18,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "",
        "rating": 5,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Black"]
    },
    {
        "id": 14,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3602.JPG", "product/main/ChamaaModaProduct/036A3602.JPG"],
        "stock": 18,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "",
        "rating": 5,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Black"]
    },
    {
        "id": 15,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3607.JPG", "product/main/ChamaaModaProduct/036A3607.JPG"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "",
        "rating": 5,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["White"]
    },
    {
        "id": 16,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3612.JPG", "product/main/ChamaaModaProduct/036A3612.JPG"],
        "stock": 18,
        "price": 4700,
        "discount": 600,
        "salePrice": 1300,
        "description": "",
        "rating": 5,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["White"]
    },
    {
        "id": 17,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3613.JPG", "product/main/ChamaaModaProduct/036A3613.JPG"],
        "stock": 18,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "",
        "rating": 5,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Gold"]
    },
    {
        "id": 18,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3616.JPG", "product/main/ChamaaModaProduct/036A3616.JPG"],
        "stock": 18,
        "price": 2400,
        "discount": 400,
        "salePrice": 1800,
        "description": "",
        "rating": 5,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Gold"]
    },
    {
        "id": 19,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3617.JPG", "product/main/ChamaaModaProduct/036A3617.JPG"],
        "stock": 18,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "",
        "rating": 5,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Gold"]
    },
    {
        "id": 20,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3618.JPG", "product/main/ChamaaModaProduct/036A3618.JPG"],
        "stock": 18,
        "price": 3200,
        "discount": 1000,
        "salePrice": 2600,
        "description": "",
        "rating": 5,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Red"]
    },
    {
        "id": 21,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3621.JPG", "product/main/ChamaaModaProduct/036A3621.JPG"],
        "stock": 18,
        "price": 1900,
        "discount": 500,
        "salePrice": 1300,
        "description": "",
        "rating": 5,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 22,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3628.JPG", "product/main/ChamaaModaProduct/036A3628.JPG"],
        "stock": 18,
        "price": 4200,
        "discount": 500,
        "salePrice": 3700,
        "description": "",
        "rating": 5,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Blue"]
    },
    {
        "id": 23,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3634.JPG", "product/main/ChamaaModaProduct/036A3634.JPG"],
        "stock": 18,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "",
        "rating": 5,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Gold"]
    },
    {
        "id": 24,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3637.JPG", "product/main/ChamaaModaProduct/036A3637.JPG"],
        "stock": 18,
        "price": 1800,
        "discount": 300,
        "salePrice": 1500,
        "description": "",
        "rating": 5,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 25,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3642.JPG", "product/main/ChamaaModaProduct/036A3642.JPG"],
        "stock": 18,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "",
        "rating": 5,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Gold"]
    },
    {
        "id": 26,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3647.JPG", "product/main/ChamaaModaProduct/036A3647.JPG"],
        "stock": 18,
        "price": 4200,
        "discount": 500,
        "salePrice": 3700,
        "description": "",
        "rating": 5,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Gold"]
    },
    {
        "id": 27,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3648.JPG", "product/main/ChamaaModaProduct/036A3648.JPG"],
        "stock": 18,
        "price": 1800,
        "discount": 300,
        "salePrice": 1500,
        "description": "",
        "rating": 5,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Blue"]
    },
    {
        "id": 28,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3653.JPG", "product/main/ChamaaModaProduct/036A3653.JPG"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "",
        "rating": 5,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Blue"]
    },
    {
        "id": 29,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3657.JPG", "product/main/ChamaaModaProduct/036A3657.JPG"],
        "stock": 18,
        "price": 1900,
        "discount": 500,
        "salePrice": 1300,
        "description": "",
        "rating": 5,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Pink"]
    },
    {
        "id": 30,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3662.JPG", "product/main/ChamaaModaProduct/036A3662.JPG"],
        "stock": 18,
        "price": 4200,
        "discount": 500,
        "salePrice": 3700,
        "description": "",
        "rating": 5,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Pink"]
    },
    {
        "id": 31,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3663.JPG", "product/main/ChamaaModaProduct/036A3663.JPG"],
        "stock": 18,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "",
        "rating": 5,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Pink"]
    },
    {
        "id": 32,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3666.JPG", "product/main/ChamaaModaProduct/036A3666.JPG"],
        "stock": 18,
        "price": 1800,
        "discount": 300,
        "salePrice": 1500,
        "description": "",
        "rating": 5,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Pink"]
    },

    {
        "id": 33,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3667.JPG", "product/main/ChamaaModaProduct/036A3667.JPG"],
        "stock": 32,
        "price": 2290,
        "discount": 500,
        "salePrice": 1790,
        "description": "",
        "rating": 3,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Purple"]
    },
    {
        "id": 34,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3675.JPG", "product/main/ChamaaModaProduct/036A3675.JPG"],
        "stock": 46,
        "price": 2290,
        "discount": 500,
        "salePrice": 1790,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Gray", "Red"]
    },
    {
        "id": 35,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3678.JPG", "product/main/ChamaaModaProduct/036A3678.JPG"],
        "stock": 20,
        "price": 2290,
        "discount": 500,
        "salePrice": 1790,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Gray"]
    },
    {
        "id": 36,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3679.JPG", "product/main/ChamaaModaProduct/036A3679.JPG"],
        "stock": 15,
        "price": 2990,
        "discount": 700,
        "salePrice": 2290,
        "description": "",
        "rating": 2,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Black"]
    },
    {
        "id": 37,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3680.JPG", "product/main/ChamaaModaProduct/036A3680.JPG"],
        "stock": 5,
        "price": 2290,
        "discount": 500,
        "salePrice": 1790,
        "description": "",
        "rating": 3,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Beige"]
    },
    {
        "id": 38,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3683.JPG", "product/main/ChamaaModaProduct/036A3683.JPG"],
        "stock": 12,
        "price": 3990,
        "discount": 1000,
        "salePrice": 2990,
        "description": "",
        "rating": 5,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Pink"]
    },
    {
        "id": 39,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3685.JPG", "product/main/ChamaaModaProduct/036A3685.JPG"],
        "stock": 23,
        "price": 2890,
        "discount": 600,
        "salePrice": 2290,
        "description": "",
        "rating": 2,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Red"]
    },
    {
        "id": 40,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3686.JPG", "product/main/ChamaaModaProduct/036A3686.JPG"],
        "stock": 34,
        "price": 2890,
        "discount": 600,
        "salePrice": 2290,
        "description": "",
        "rating": 5,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Red"]
    },
    {
        "id": 41,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3689.JPG", "product/main/ChamaaModaProduct/036A3689.JPG"],
        "stock": 3,
        "price": 2890,
        "discount": 600,
        "salePrice": 2290,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Blue"]
    },
    {
        "id": 42,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3691.JPG", "product/main/ChamaaModaProduct/036A3691.JPG"],
        "stock": 42,
        "price": 4990,
        "discount": 2000,
        "salePrice": 2990,
        "description": "",
        "rating": 3,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Brown"]
    },

    {
        "id": 43,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3692.JPG", "product/main/ChamaaModaProduct/036A3692.JPG"],
        "stock": 15,
        "price": 1590,
        "discount": 318,
        "salePrice": 1272,
        "description": "",
        "rating": 1,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Orange"]
    },
    {
        "id": 44,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3693.JPG", "product/main/ChamaaModaProduct/036A3693.JPG"],
        "stock": 12,
        "price": 2790,
        "discount": 558,
        "salePrice": 2232,
        "description": "",
        "rating": 2,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Gray"]
    },
    {
        "id": 45,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3696.JPG", "product/main/ChamaaModaProduct/036A3696.JPG"],
        "stock": 6,
        "price": 1590,
        "discount": 318,
        "salePrice": 1272,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Blue"]
    },
    {
        "id": 46,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3699.JPG", "product/main/ChamaaModaProduct/036A3699.JPG"],
        "stock": 11,
        "price": 1590,
        "discount": 318,
        "salePrice": 1272,
        "description": "",
        "rating": 1,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Red"]
    },
    {
        "id": 47,
        "name": " Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3702.JPG", "product/main/ChamaaModaProduct/036A3702.JPG"],
        "stock": 5,
        "price": 990,
        "discount": 198,
        "salePrice": 792,
        "description": "",
        "rating": 5,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Black"]
    },
    {
        "id": 48,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3715.JPG", "product/main/ChamaaModaProduct/036A3715.JPG"],
        "stock": 15,
        "price": 690,
        "discount": 138,
        "salePrice": 552,
        "description": "",
        "rating": 3,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 49,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3719.JPG", "product/main/ChamaaModaProduct/036A3719.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 50,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3720.JPG", "product/main/ChamaaModaProduct/036A3720.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 51,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3727.JPG", "product/main/ChamaaModaProduct/036A3727.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 52,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3732.JPG", "product/main/ChamaaModaProduct/036A3732.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 53,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3738.JPG", "product/main/ChamaaModaProduct/036A3738.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 54,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3739.JPG", "product/main/ChamaaModaProduct/036A3739.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 55,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3740.JPG", "product/main/ChamaaModaProduct/036A3740.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 56,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3745.JPG", "product/main/ChamaaModaProduct/036A3745.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 57,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3749.JPG", "product/main/ChamaaModaProduct/036A3749.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 58,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3752.JPG", "product/main/ChamaaModaProduct/036A3752.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 59,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3753.JPG", "product/main/ChamaaModaProduct/036A3753.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 60,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3758.JPG", "product/main/ChamaaModaProduct/036A3758.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 61,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3760.JPG", "product/main/ChamaaModaProduct/036A3760.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 62,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3762.JPG", "product/main/ChamaaModaProduct/036A3762.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 63,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3764.JPG", "product/main/ChamaaModaProduct/036A3764.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 64,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3770.JPG", "product/main/ChamaaModaProduct/036A3770.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 65,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3771.JPG", "product/main/ChamaaModaProduct/036A3771.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 66,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3779.JPG", "product/main/ChamaaModaProduct/036A3779.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 67,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3781.JPG", "product/main/ChamaaModaProduct/036A3781.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 68,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3784.JPG", "product/main/ChamaaModaProduct/036A3784.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 69,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3785.JPG", "product/main/ChamaaModaProduct/036A3785.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 70,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3790.JPG", "product/main/ChamaaModaProduct/036A3790.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 71,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3795.JPG", "product/main/ChamaaModaProduct/036A3795.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 72,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3801.JPG", "product/main/ChamaaModaProduct/036A3801.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 73,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3803.JPG", "product/main/ChamaaModaProduct/036A3803.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 74,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3809.JPG", "product/main/ChamaaModaProduct/036A3809.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 75,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3811.JPG", "product/main/ChamaaModaProduct/036A3811.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 76,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3816.JPG", "product/main/ChamaaModaProduct/036A3816.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 77,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3819.JPG", "product/main/ChamaaModaProduct/036A3819.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 78,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3823.JPG", "product/main/ChamaaModaProduct/036A3823.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 79,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3825.JPG", "product/main/ChamaaModaProduct/036A3825.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 80,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3826.JPG", "product/main/ChamaaModaProduct/036A3826.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 81,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3828.JPG", "product/main/ChamaaModaProduct/036A3828.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 82,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3830.JPG", "product/main/ChamaaModaProduct/036A3830.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 83,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3832.JPG", "product/main/ChamaaModaProduct/036A3832.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 84,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3836.JPG", "product/main/ChamaaModaProduct/036A3836.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },

    {
        "id": 85,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3837.JPG", "product/main/ChamaaModaProduct/036A3837.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    }, {
        "id": 86,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3838.JPG", "product/main/ChamaaModaProduct/036A3838.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 87,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3840.JPG", "product/main/ChamaaModaProduct/036A3840.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 88,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3843.JPG", "product/main/ChamaaModaProduct/036A3843.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 89,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3844.JPG", "product/main/ChamaaModaProduct/036A3844.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 90,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3846.JPG", "product/main/ChamaaModaProduct/036A3846.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 91,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3847.JPG", "product/main/ChamaaModaProduct/036A3847.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 92,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3849.JPG", "product/main/ChamaaModaProduct/036A3849.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 93,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3854.JPG", "product/main/ChamaaModaProduct/036A3854.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 94,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3855.JPG", "product/main/ChamaaModaProduct/036A3855.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 95,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3856.JPG", "product/main/ChamaaModaProduct/036A3856.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 96,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3857.JPG", "product/main/ChamaaModaProduct/036A3857.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 97,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3858.JPG", "product/main/ChamaaModaProduct/036A3858.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 98,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3859.JPG", "product/main/ChamaaModaProduct/036A3859.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 99,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3863.JPG", "product/main/ChamaaModaProduct/036A3863.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 100,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3866.JPG", "product/main/ChamaaModaProduct/036A3866.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 101,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3868.JPG", "product/main/ChamaaModaProduct/036A3868.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 102,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3872.JPG", "product/main/ChamaaModaProduct/036A3872.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 103,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3874.JPG", "product/main/ChamaaModaProduct/036A3874.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 104,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3877.JPG", "product/main/ChamaaModaProduct/036A3877.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },

    {
        "id": 105,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3880.JPG", "product/main/ChamaaModaProduct/036A3880.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 106,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3882.JPG", "product/main/ChamaaModaProduct/036A3882.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 107,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3884.JPG", "product/main/ChamaaModaProduct/036A3884.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 108,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3887.JPG", "product/main/ChamaaModaProduct/036A3887.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 109,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3892.JPG", "product/main/ChamaaModaProduct/036A3892.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 110,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3895.JPG", "product/main/ChamaaModaProduct/036A3895.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 111,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3906.JPG", "product/main/ChamaaModaProduct/036A3906.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 112,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3911.JPG", "product/main/ChamaaModaProduct/036A3911.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 113,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3913.JPG", "product/main/ChamaaModaProduct/036A3913.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 114,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3917.JPG", "product/main/ChamaaModaProduct/036A3917.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 115,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3925.JPG", "product/main/ChamaaModaProduct/036A3925.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 116,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3929.JPG", "product/main/ChamaaModaProduct/036A3929.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },

    {
        "id": 117,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3930.JPG", "product/main/ChamaaModaProduct/036A3930.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 118,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3935.JPG", "product/main/ChamaaModaProduct/036A3935.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 119,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3939.JPG", "product/main/ChamaaModaProduct/036A3939.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 120,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3941.JPG", "product/main/ChamaaModaProduct/036A3941.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 121,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3944.JPG", "product/main/ChamaaModaProduct/036A3944.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 122,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A3949.JPG", "product/main/ChamaaModaProduct/036A3949.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 123,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A3954.JPG", "product/main/ChamaaModaProduct/036A3954.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 124,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A3956.JPG", "product/main/ChamaaModaProduct/036A3956.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 125,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A4299.JPG", "product/main/ChamaaModaProduct/036A4299.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 126,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A4305.JPG", "product/main/ChamaaModaProduct/036A4305.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 127,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4309.JPG", "product/main/ChamaaModaProduct/036A4309.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 128,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4311.JPG", "product/main/ChamaaModaProduct/036A4311.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 129,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4315.JPG", "product/main/ChamaaModaProduct/036A4315.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 130,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A4316.JPG", "product/main/ChamaaModaProduct/036A4316.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 131,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4321.JPG", "product/main/ChamaaModaProduct/036A4321.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 132,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A4322.JPG", "product/main/ChamaaModaProduct/036A4322.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 133,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A4324.JPG", "product/main/ChamaaModaProduct/036A4324.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 134,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A4330.JPG", "product/main/ChamaaModaProduct/036A4330.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 135,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4333.JPG", "product/main/ChamaaModaProduct/036A4333.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 136,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4334.JPG", "product/main/ChamaaModaProduct/036A4334.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 137,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4335.JPG", "product/main/ChamaaModaProduct/036A4335.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 138,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A4338.JPG", "product/main/ChamaaModaProduct/036A4338.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 139,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4339.JPG", "product/main/ChamaaModaProduct/036A4339.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
    {
        "id": 140,
        "name": "Embroidery Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A4341.JPG", "product/main/ChamaaModaProduct/036A4341.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Embroidery Ribbon"],
        "category": "Embroidery Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 141,
        "name": "Jacquard Trim Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A4346.JPG", "product/main/ChamaaModaProduct/036A4346.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Jacquard Trim Ribbon"],
        "category": "Jacquard Trim Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 142,
        "name": "Rhinestone Chain Ribbon",
        "pictures": ["product/main/ChamaaModaProduct/036A4349.JPG", "product/main/ChamaaModaProduct/036A4349.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Rhinestone Chain Ribbon"],
        "category": "Rhinestone Chain Ribbon",
        "colors": ["Green"]
    },
    {
        "id": 143,
        "name": "Plastic Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4353.JPG", "product/main/ChamaaModaProduct/036A4353.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Plastic Trim"],
        "category": "Plastic Trim",
        "colors": ["Green"]
    },
    {
        "id": 144,
        "name": "Crochet Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4360.JPG", "product/main/ChamaaModaProduct/036A4360.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crochet Trim"],
        "category": "Crochet Trim",
        "colors": ["Green"]
    },
    {
        "id": 145,
        "name": "Floral Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4362.JPG", "product/main/ChamaaModaProduct/036A4362.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Floral Trim"],
        "category": "Floral Trim",
        "colors": ["Green"]
    },
    {
        "id": 146,
        "name": "Knitted Metallic Net",
        "pictures": ["product/main/ChamaaModaProduct/036A4364.JPG", "product/main/ChamaaModaProduct/036A4364.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Knitted Metallic Net"],
        "category": "Knitted Metallic Net",
        "colors": ["Green"]
    },
    {
        "id": 147,
        "name": "Crystal Trim",
        "pictures": ["product/main/ChamaaModaProduct/036A4379.JPG", "product/main/ChamaaModaProduct/036A4379.JPG"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "",
        "rating": 4,
        "tags": ["Crystal Trim"],
        "category": "Crystal Trim",
        "colors": ["Green"]
    },
]