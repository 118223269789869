import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {

  function openWhatsApp() {
    // Replace '1234567890' with the phone number you want to open WhatsApp chat with
    var phoneNumber = '+9613399431';
    
    // Open WhatsApp with the provided phone number
    window.open('https://wa.me/' + phoneNumber, '_blank');
  }
  return (
    <>
      <footer className="py-11 bg-dark">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              {" "}
              <Link className="footer-logo text-white h2 mb-0" to="/">
                Chamaa<span className="text-primary">Moda</span>
              </Link>
              <p className="my-3 text-muted">
                Chamaa Moda - Timeless fashion accessories since 1930. Elevate
                your designs with high-quality trims, patches, and buttons.
                Tradition meets contemporary style for your garment needs.
              </p>
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <Link className="text-light ic-2x" to="#">
                    <i className="la la-facebook"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link className="text-light ic-2x" to="#">
                    <i className="la la-instagram"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link className="text-light ic-2x" to="#" onClick={openWhatsApp}>
                    <i className="la la-whatsapp"></i>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-lg-6 mt-6 mt-lg-0">
              <div className="row">
                <div className="col-12 col-sm-6 navbar-dark">
                  <h5 className="mb-4 text-white">Quick Links</h5>
                  <ul className="navbar-nav list-unstyled mb-0">
                    <li className="mb-3 nav-item">
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="mb-3 nav-item">
                      <Link className="nav-link" to="/about-us">
                        About
                      </Link>
                    </li>
                    <li className="mb-3 nav-item">
                      <Link className="nav-link" to="/gallery">
                        Gallery
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/contact-us">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-sm-6 mt-6 mt-sm-0 navbar-dark">
                  <h5 className="mb-4 text-white">Top Products</h5>
                  <ul className="navbar-nav list-unstyled mb-0">
                    <li className="mb-3 nav-item">
                      <Link className="nav-link" to="#">
                      Trimmings for Abaya 
                      </Link>
                    </li>
                    <li className="mb-3 nav-item">
                      <Link className="nav-link" to="#">
                      Dantiel 
                      </Link>
                    </li>
                    <li className="mb-3 nav-item">
                      <Link className="nav-link" to="#">
                      Reel Feather 
                      </Link>
                    </li>
                    <li className="mb-3 nav-item">
                      <Link className="nav-link" to="#">
                      Printed Fabric
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mt-6 mt-lg-0">
              <div className="d-flex mb-3">
                <div className="mr-2">
                  {" "}
                  <i className="las la-map ic-2x text-primary"></i>
                </div>
                <div>
                  <h6 className="mb-1 text-light">Store address</h6>
                  <p className="mb-0 text-muted">
                    Beirut, Kaskas Street, Saade Building
                  </p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="mr-2">
                  {" "}
                  <i className="las la-envelope ic-2x text-primary"></i>
                </div>
                <div>
                  <h6 className="mb-1 text-light">Email Us</h6>
                  <Link
                    className="text-muted"
                    to="mailto:abed.chamaa@hotmail.com"
                  >
                    {" "}
                    abed.chamaa@hotmail.com{" "}
                  </Link>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="mr-2">
                  {" "}
                  <i className="las la-mobile ic-2x text-primary"></i>
                </div>
                <div>
                  <h6 className="mb-1 text-light">Phone Number</h6>
                  <Link className="text-muted" to="tel:+9611640715">
                    01 640 715
                  </Link>
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className="mr-2">
                  {" "}
                  <i className="las la-mobile ic-2x text-primary"></i>
                </div>
                <div>
                  <h6 className="mb-1 text-light">Mobile Number</h6>
                  <Link className="text-muted" to="tel:+9613399431">
                    03 399 431
                  </Link>
                </div>
              </div>
              <div className="d-flex">
                <div className="mr-2">
                  {" "}
                  <i className="las la-clock ic-2x text-primary"></i>
                </div>
                <div>
                  <h6 className="mb-1 text-light">Working Hours</h6>
                  <span className="text-muted">Mon – Fri: 8:30 AM – 6 PM</span><br/>
                  <span className="text-muted">Sat: 8:30 AM – 2 PM</span>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-8" />
          <div className="row text-muted align-items-center">
            <div className="col-md-7">
              Copyright ©{new Date().getFullYear()} All rights reserved | Made
              by <i className="lar la-heart text-primary heartBeat2"></i>{" "}
              <u>
                <Link className="text-primary" to="https://vision-more.com/">
                  Vision&more
                </Link>
              </u>
            </div>
         
          </div>
        </div>
      </footer>
    </>
  );
}
