const blogList = [
  {
    id: 1,
    image: "assets/images/product/main/ChamaaModaProduct/036A3557.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 2,
    image: "assets/images/product/main/ChamaaModaProduct/036A3566.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 3,
    image: "assets/images/product/main/ChamaaModaProduct/036A3570.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 4,
    image: "assets/images/product/main/ChamaaModaProduct/036A3572.JPG",
    category: "Plastic Trim",
  },
  {
    id: 5,
    image: "assets/images/product/main/ChamaaModaProduct/036A3575.JPG",
    category: "Crochet Trim",
  },

  {
    id: 6,
    image: "assets/images/product/main/ChamaaModaProduct/036A3579.JPG",
    category: "Floral Trim",
  },
  {
    id: 7,
    image: "assets/images/product/main/ChamaaModaProduct/036A3581.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 8,
    image: "assets/images/product/main/ChamaaModaProduct/036A3582.JPG",
    category: "Crystal Trim",
  },
  {
    id: 9,
    image: "assets/images/product/main/ChamaaModaProduct/036A3585.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 10,
    image: "assets/images/product/main/ChamaaModaProduct/036A3589.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 11,
    image: "assets/images/product/main/ChamaaModaProduct/036A3593.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 12,
    image: "assets/images/product/main/ChamaaModaProduct/036A3596.JPG",
    category: "Plastic Trim",
  },
  {
    id: 13,
    image: "assets/images/product/main/ChamaaModaProduct/036A3598.JPG",
    category: "Crochet Trim",
  },
  {
    id: 14,
    image: "assets/images/product/main/ChamaaModaProduct/036A3602.JPG",
    category: "Floral Trim",
  },
  {
    id: 15,
    image: "assets/images/product/main/ChamaaModaProduct/036A3607.JPG",
    category: "Crochet Trim",
  },
  {
    id: 16,
    image: "assets/images/product/main/ChamaaModaProduct/036A3612.JPG",
    category: "Floral Trim",
  },
  {
    id: 17,
    image: "assets/images/product/main/ChamaaModaProduct/036A3613.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 18,
    image: "assets/images/product/main/ChamaaModaProduct/036A3616.JPG",
    category: "Crystal Trim",
  },
  {
    id: 19,
    image: "assets/images/product/main/ChamaaModaProduct/036A3617.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 20,
    image: "assets/images/product/main/ChamaaModaProduct/036A3618.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 21,
    image: "assets/images/product/main/ChamaaModaProduct/036A3621.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 22,
    image: "assets/images/product/main/ChamaaModaProduct/036A3628.JPG",
    category: "Floral Trim",
  },
  {
    id: 23,
    image: "assets/images/product/main/ChamaaModaProduct/036A3634.JPG",
    category: "Plastic Trim",
  },
  {
    id: 24,
    image: "assets/images/product/main/ChamaaModaProduct/036A3637.JPG",
    category: "Crochet Trim",
  },
  {
    id: 25,
    image: "assets/images/product/main/ChamaaModaProduct/036A3642.JPG",
    category: "Floral Trim",
  },
  {
    id: 26,
    image: "assets/images/product/main/ChamaaModaProduct/036A3647.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 27,
    image: "assets/images/product/main/ChamaaModaProduct/036A3648.JPG",
    category: "Crystal Trim",
  },
  {
    id: 28,
    image: "assets/images/product/main/ChamaaModaProduct/036A3653.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 29,
    image: "assets/images/product/main/ChamaaModaProduct/036A3657.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 30,
    image: "assets/images/product/main/ChamaaModaProduct/036A3662.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 31,
    image: "assets/images/product/main/ChamaaModaProduct/036A3663.JPG",
    category: "Plastic Trim",
  },
  {
    id: 32,
    image: "assets/images/product/main/ChamaaModaProduct/036A3666.JPG",
    category: "Crochet Trim",
  },

  {
    id: 33,
    image: "assets/images/product/main/ChamaaModaProduct/036A3667.JPG",
    category: "Floral Trim",
  },
  {
    id: 34,
    image: "assets/images/product/main/ChamaaModaProduct/036A3675.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 35,
    image: "assets/images/product/main/ChamaaModaProduct/036A3678.JPG",
    category: "Crystal Trim",
  },
  {
    id: 36,
    image: "assets/images/product/main/ChamaaModaProduct/036A3679.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 37,
    image: "assets/images/product/main/ChamaaModaProduct/036A3680.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 38,
    image: "assets/images/product/main/ChamaaModaProduct/036A3683.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 39,
    image: "assets/images/product/main/ChamaaModaProduct/036A3685.JPG",
    category: "Plastic Trim",
  },
  {
    id: 40,
    image: "assets/images/product/main/ChamaaModaProduct/036A3686.JPG",
    category: "Crochet Trim",
  },
  {
    id: 41,
    image: "assets/images/product/main/ChamaaModaProduct/036A3689.JPG",
    category: "Floral Trim",
  },
  {
    id: 42,
    image: "assets/images/product/main/ChamaaModaProduct/036A3691.JPG",
    category: "Knitted Metallic Net",
  },

  {
    id: 43,
    image: "assets/images/product/main/ChamaaModaProduct/036A3692.JPG",
    category: "Crystal Trim",
  },
  {
    id: 44,
    image: "assets/images/product/main/ChamaaModaProduct/036A3693.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 45,
    image: "assets/images/product/main/ChamaaModaProduct/036A3696.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 46,
    image: "assets/images/product/main/ChamaaModaProduct/036A3699.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 47,
    image: "assets/images/product/main/ChamaaModaProduct/036A3702.JPG",
    category: "Plastic Trim",
  },
  {
    id: 48,
    image: "assets/images/product/main/ChamaaModaProduct/036A3715.JPG",
    category: "Crochet Trim",
  },
  {
    id: 49,
    image: "assets/images/product/main/ChamaaModaProduct/036A3719.JPG",
    category: "Floral Trim",
  },
  {
    id: 50,
    image: "assets/images/product/main/ChamaaModaProduct/036A3720.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 51,
    image: "assets/images/product/main/ChamaaModaProduct/036A3727.JPG",
    category: "Crystal Trim",
  },
  {
    id: 52,
    image: "assets/images/product/main/ChamaaModaProduct/036A3732.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 53,
    image: "assets/images/product/main/ChamaaModaProduct/036A3738.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 54,
    image: "assets/images/product/main/ChamaaModaProduct/036A3739.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 55,
    image: "assets/images/product/main/ChamaaModaProduct/036A3740.JPG",
    category: "Plastic Trim",
  },
  {
    id: 56,
    image: "assets/images/product/main/ChamaaModaProduct/036A3745.JPG",
    category: "Crochet Trim",
  },
  {
    id: 57,
    image: "assets/images/product/main/ChamaaModaProduct/036A3749.JPG",
    category: "Floral Trim",
  },
  {
    id: 58,
    image: "assets/images/product/main/ChamaaModaProduct/036A3752.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 59,
    image: "assets/images/product/main/ChamaaModaProduct/036A3753.JPG",
    category: "Crystal Trim",
  },
  {
    id: 60,
    image: "assets/images/product/main/ChamaaModaProduct/036A3758.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 61,
    image: "assets/images/product/main/ChamaaModaProduct/036A3760.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 62,
    image: "assets/images/product/main/ChamaaModaProduct/036A3762.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 63,
    image: "assets/images/product/main/ChamaaModaProduct/036A3764.JPG",
    category: "Plastic Trim",
  },
  {
    id: 64,
    image: "assets/images/product/main/ChamaaModaProduct/036A3770.JPG",
    category: "Crochet Trim",
  },
  {
    id: 65,
    image: "assets/images/product/main/ChamaaModaProduct/036A3771.JPG",
    category: "Floral Trim",
  },
  {
    id: 66,
    image: "assets/images/product/main/ChamaaModaProduct/036A3779.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 67,
    image: "assets/images/product/main/ChamaaModaProduct/036A3781.JPG",
    category: "Crystal Trim",
  },
  {
    id: 68,
    image: "assets/images/product/main/ChamaaModaProduct/036A3784.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 69,
    image: "assets/images/product/main/ChamaaModaProduct/036A3785.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 70,
    image: "assets/images/product/main/ChamaaModaProduct/036A3790.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 71,
    image: "assets/images/product/main/ChamaaModaProduct/036A3795.JPG",
    category: "Plastic Trim",
  },
  {
    id: 72,
    image: "assets/images/product/main/ChamaaModaProduct/036A3801.JPG",
    category: "Crochet Trim",
  },
  {
    id: 73,
    image: "assets/images/product/main/ChamaaModaProduct/036A3803.JPG",
    category: "Floral Trim",
  },
  {
    id: 74,
    image: "assets/images/product/main/ChamaaModaProduct/036A3809.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 75,
    image: "assets/images/product/main/ChamaaModaProduct/036A3811.JPG",
    category: "Crystal Trim",
  },
  {
    id: 76,
    image: "assets/images/product/main/ChamaaModaProduct/036A3816.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 77,
    image: "assets/images/product/main/ChamaaModaProduct/036A3819.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 78,
    image: "assets/images/product/main/ChamaaModaProduct/036A3823.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 79,
    image: "assets/images/product/main/ChamaaModaProduct/036A3825.JPG",
    category: "Plastic Trim",
  },
  {
    id: 80,
    image: "assets/images/product/main/ChamaaModaProduct/036A3826.JPG",
    category: "Crochet Trim",
  },
  {
    id: 81,
    image: "assets/images/product/main/ChamaaModaProduct/036A3828.JPG",
    category: "Floral Trim",
  },
  {
    id: 82,
    image: "assets/images/product/main/ChamaaModaProduct/036A3830.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 83,
    image: "assets/images/product/main/ChamaaModaProduct/036A3832.JPG",
    category: "Crystal Trim",
  },
  {
    id: 84,
    image: "assets/images/product/main/ChamaaModaProduct/036A3836.JPG",
    category: "Embroidery Ribbon",
  },

  {
    id: 85,
    image: "assets/images/product/main/ChamaaModaProduct/036A3837.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 86,
    image: "assets/images/product/main/ChamaaModaProduct/036A3838.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 87,
    image: "assets/images/product/main/ChamaaModaProduct/036A3840.JPG",
    category: "Plastic Trim",
  },
  {
    id: 88,
    image: "assets/images/product/main/ChamaaModaProduct/036A3843.JPG",
    category: "Crochet Trim",
  },
  {
    id: 89,
    image: "assets/images/product/main/ChamaaModaProduct/036A3844.JPG",
    category: "Floral Trim",
  },
  {
    id: 90,
    image: "assets/images/product/main/ChamaaModaProduct/036A3846.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 91,
    image: "assets/images/product/main/ChamaaModaProduct/036A3847.JPG",
    category: "Crystal Trim",
  },
  {
    id: 92,
    image: "assets/images/product/main/ChamaaModaProduct/036A3849.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 93,
    image: "assets/images/product/main/ChamaaModaProduct/036A3854.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 94,
    image: "assets/images/product/main/ChamaaModaProduct/036A3855.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 95,
    image: "assets/images/product/main/ChamaaModaProduct/036A3856.JPG",
    category: "Plastic Trim",
  },
  {
    id: 96,
    image: "assets/images/product/main/ChamaaModaProduct/036A3857.JPG",
    category: "Crochet Trim",
  },
  {
    id: 97,
    image: "assets/images/product/main/ChamaaModaProduct/036A3858.JPG",
    category: "Floral Trim",
  },
  {
    id: 98,
    image: "assets/images/product/main/ChamaaModaProduct/036A3859.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 99,
    image: "assets/images/product/main/ChamaaModaProduct/036A3863.JPG",
    category: "Crystal Trim",
  },
  {
    id: 100,
    image: "assets/images/product/main/ChamaaModaProduct/036A3866.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 101,
    image: "assets/images/product/main/ChamaaModaProduct/036A3868.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 102,
    image: "assets/images/product/main/ChamaaModaProduct/036A3872.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 103,
    image: "assets/images/product/main/ChamaaModaProduct/036A3874.JPG",
    category: "Plastic Trim",
  },
  {
    id: 104,
    image: "assets/images/product/main/ChamaaModaProduct/036A3877.JPG",
    category: "Crochet Trim",
  },

  {
    id: 105,
    image: "assets/images/product/main/ChamaaModaProduct/036A3880.JPG",
    category: "Floral Trim",
  },
  {
    id: 106,
    image: "assets/images/product/main/ChamaaModaProduct/036A3882.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 107,
    image: "assets/images/product/main/ChamaaModaProduct/036A3884.JPG",
    category: "Crystal Trim",
  },
  {
    id: 108,
    image: "assets/images/product/main/ChamaaModaProduct/036A3887.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 109,
    image: "assets/images/product/main/ChamaaModaProduct/036A3892.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 110,
    image: "assets/images/product/main/ChamaaModaProduct/036A3895.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 111,
    image: "assets/images/product/main/ChamaaModaProduct/036A3906.JPG",
    category: "Plastic Trim",
  },
  {
    id: 112,
    image: "assets/images/product/main/ChamaaModaProduct/036A3911.JPG",
    category: "Crochet Trim",
  },
  {
    id: 113,
    image: "assets/images/product/main/ChamaaModaProduct/036A3913.JPG",
    category: "Floral Trim",
  },
  {
    id: 114,
    image: "assets/images/product/main/ChamaaModaProduct/036A3917.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 115,
    image: "assets/images/product/main/ChamaaModaProduct/036A3925.JPG",
    category: "Crystal Trim",
  },
  {
    id: 116,
    image: "assets/images/product/main/ChamaaModaProduct/036A3929.JPG",
    category: "Embroidery Ribbon",
  },

  {
    id: 117,
    image: "assets/images/product/main/ChamaaModaProduct/036A3930.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 118,
    image: "assets/images/product/main/ChamaaModaProduct/036A3935.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 119,
    image: "assets/images/product/main/ChamaaModaProduct/036A3939.JPG",
    category: "Crochet Trim",
  },
  {
    id: 120,
    image: "assets/images/product/main/ChamaaModaProduct/036A3941.JPG",
    category: "Crochet Trim",
  },
  {
    id: 121,
    image: "assets/images/product/main/ChamaaModaProduct/036A3944.JPG",
    category: "Floral Trim",
  },
  {
    id: 122,
    image: "assets/images/product/main/ChamaaModaProduct/036A3949.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 123,
    image: "assets/images/product/main/ChamaaModaProduct/036A3954.JPG",
    category: "Crystal Trim",
  },
  {
    id: 124,
    image: "assets/images/product/main/ChamaaModaProduct/036A3956.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 125,
    image: "assets/images/product/main/ChamaaModaProduct/036A4299.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 126,
    image: "assets/images/product/main/ChamaaModaProduct/036A4305.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 127,
    image: "assets/images/product/main/ChamaaModaProduct/036A4309.JPG",
    category: "Plastic Trim",
  },
  {
    id: 128,
    image: "assets/images/product/main/ChamaaModaProduct/036A4311.JPG",
    category: "Crochet Trim",
  },
  {
    id: 129,
    image: "assets/images/product/main/ChamaaModaProduct/036A4315.JPG",
    category: "Floral Trim",
  },
  {
    id: 130,
    image: "assets/images/product/main/ChamaaModaProduct/036A4316.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 131,
    image: "assets/images/product/main/ChamaaModaProduct/036A4321.JPG",
    category: "Crystal Trim",
  },
  {
    id: 132,
    image: "assets/images/product/main/ChamaaModaProduct/036A4322.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 133,
    image: "assets/images/product/main/ChamaaModaProduct/036A4324.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 134,
    image: "assets/images/product/main/ChamaaModaProduct/036A4330.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 135,
    image: "assets/images/product/main/ChamaaModaProduct/036A4333.JPG",
    category: "Plastic Trim",
  },
  {
    id: 136,
    image: "assets/images/product/main/ChamaaModaProduct/036A4334.JPG",
    category: "Crochet Trim",
  },
  {
    id: 137,
    image: "assets/images/product/main/ChamaaModaProduct/036A4335.JPG",
    category: "Floral Trim",
  },
  {
    id: 138,
    image: "assets/images/product/main/ChamaaModaProduct/036A4338.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 139,
    image: "assets/images/product/main/ChamaaModaProduct/036A4339.JPG",
    category: "Crystal Trim",
  },
  {
    id: 140,
    image: "assets/images/product/main/ChamaaModaProduct/036A4341.JPG",
    category: "Embroidery Ribbon",
  },
  {
    id: 141,
    image: "assets/images/product/main/ChamaaModaProduct/036A4346.JPG",
    category: "Jacquard Trim Ribbon",
  },
  {
    id: 142,
    image: "assets/images/product/main/ChamaaModaProduct/036A4349.JPG",
    category: "Rhinestone Chain Ribbon",
  },
  {
    id: 143,
    image: "assets/images/product/main/ChamaaModaProduct/036A4353.JPG",
    category: "Plastic Trim",
  },
  {
    id: 144,
    image: "assets/images/product/main/ChamaaModaProduct/036A4360.JPG",
    category: "Crochet Trim",
  },
  {
    id: 145,
    image: "assets/images/product/main/ChamaaModaProduct/036A4362.JPG",
    category: "Floral Trim",
  },
  {
    id: 146,
    image: "assets/images/product/main/ChamaaModaProduct/036A4364.JPG",
    category: "Knitted Metallic Net",
  },
  {
    id: 147,
    image: "assets/images/product/main/ChamaaModaProduct/036A4379.JPG",
    category: "Crystal Trim",
  },
];

export default blogList;
