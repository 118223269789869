import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import BlogSection from "../Components/Blog/BlogSection";
import Countdown2 from "../Components/Countdown/CountDown2";
import FeatureIndex2 from "../Components/Feature/FeatureIndex2";
import HeroBanner2 from "../Components/Hero Banner/HeroBanner2";
import InstagramSection from "../Components/InstagramSection/InstagramSection";
import NewCollection2 from "../Components/Our Products/NewCollection2";
import Section2 from "../Components/Section/Section2";
import ProductIndex2 from "../Components/Trending/ProductIndex2";
import ProductIndex6 from "../Components/Trending/ProductIndex6";
function Index2({isOpen , setIsOpen , isDropdownOpen , setIsDropdownOpen}) {
  const feature = [
    {
      icon: "las la-truck ic-2x text-primary",
      title: "Free Shipping",
      description: "Enjoy free shipping on all orders!",
    },
    {
      icon: "las la-hand-holding-usd ic-2x text-primary",
      title: "Money Return",
      description: "30-day hassle free returns - satisfaction guaranteed.",
    },
    {
      icon: "las la-lock ic-2x text-primary",
      title: "Secure Payment",
      description: "Seamless transactions with our secure Credit Card.",
    },
    {
      icon: "las la-headset ic-2x text-primary",
      title: "24/7 Support",
      description: "24/7 support, always here for you!",
    },
  ];
  const blogs = useSelector(
    (state) => state.blog.blogItems
  );
  useEffect(()=>{
    window.scrollTo(0,0)
},[])
  return <div className="page-wrapper">
    <HeroBanner2  isOpen={isOpen} setIsOpen={setIsOpen} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen}/>
    <div className="page-content">

      <ProductIndex2 />
      <Section2 />
      <FeatureIndex2 feature={feature} />
      <NewCollection2 />
    
      <ProductIndex6/>
      <InstagramSection />
    </div>
  </div>;
}

export default Index2;
