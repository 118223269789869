import React from "react";
import { Link } from "react-router-dom";

function CatalogDropDown({ title, catalogoryLinks , ToggleNavbar}) {
  return (
    <li className="nav-item dropdown position-static">
      <Link className="nav-link dropdown-toggle" to="#" data-toggle="dropdown">
        {title}
      </Link>
      <div className="dropdown-menu w-100">
        <div className="container p-0">
          <div className="row w-100 no-gutters">
            <div className="col-lg-12 p-lg-3">
              <div className="row">
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">
                    Trimmings for Clothes
                  </div>
                  <ul className="list-unstyled mb-6 mb-md-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Lace</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Ribbons</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Decorative Edging</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">
                    Trimmings for Abaya
                  </div>
                  <ul className="list-unstyled mb-6 mb-md-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="/shop-grid-left-sidebar">
                        Traditional Embroidery
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="/shop-grid-left-sidebar">
                        Modern Embellishments
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="/shop-grid-left-sidebar">
                        Abaya-Specific Trims
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">Patches</div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      {" "}
                      <Link to="shop-grid-left-sidebar">
                        Embroidered Patches
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Iron-On Patches</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Customizable Patches
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">Dantiel</div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Intricate Dantiel Designs
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Lace Dantiels</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Decorative Dantiel Elements
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">Gubier</div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Classic Gubier</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Modern Gubier Designs
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Premium Gubier Options
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">Reel Feather</div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Natural Feather Options
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Dyed Feather Selection
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Feather Trims</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">
                    Reel and Artificial Fur
                  </div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Genuine Fur</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Faux Fur</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Fur Trims</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">
                    Buttons (Metal and Polyester)
                  </div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Metal Buttons</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Polyester Buttons</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Customized Button Sets
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">Belts</div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Leather Belts</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Fabric Belts</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Decorative Belts</Link>
                    </li>
                  </ul>
                </div>

                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">Printed Fabric</div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Floral Prints</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Geometric Prints</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Abstract Patterns</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">
                    Evening Fabric Dress
                  </div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Satin Fabrics</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Sequined Fabrics</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Luxe Textures</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">Handmade Patches</div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Artisan-Crafted Designs
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Vintage-Style Patches
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Personalized Handmade Patches
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md-3 col-sm-6">
                  <div className="mb-3 font-weight-bold">Crystal Stones</div>
                  <ul className="list-unstyled mb-0">
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Rhinestones</Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">
                        Gemstone Embellishments
                      </Link>
                    </li>
                    <li onClick={()=>ToggleNavbar()}>
                      <Link to="shop-grid-left-sidebar">Crystal Studs</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-12 col-md-4">
              <img
                className="img-fluid rounded-bottom rounded-top"
                src="assets/images/header-img2.jpg"
                alt="..."
              />
            </div> */}
          </div>
        </div>
      </div>
    </li>
  );
}

export default CatalogDropDown;
