import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link  , useNavigate} from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Col, Container, Modal, ModalBody, Row } from "reactstrap";
import CatalogDropDown from "./CatalogDropDown";
import ShopDropdown from "./DropDownMenuBarTable";
import DropdownMenuNavBar from "./DropDownMenuNavBar";
export default function HeaderNavBar({isOpen , setIsOpen , setIsDropdownOpen , isDropdownOpen}) {
  const dispatch = useDispatch();
  const navigate =useNavigate()
  
  const ToggleNavbar = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(!isOpen);
      setIsDropdownOpen(false)
    }
  };

  const homeLinks = [{ url: "/", label: "Home - Fashion 1" }];

  const catagoryLinks = [
    {
      title: "Clothing",
      links: [
        { title: "All Clothing", url: "/shop-grid-left-sidebar" },
        { title: "Top T-Shirts & Shirts", url: "/shop-grid-left-sidebar" },
        { title: "Lingerie & Sleepwear", url: "/shop-grid-left-sidebar" },
        { title: "Leggings & Jeggings", url: "/shop-grid-left-sidebar" },
        { title: "Kurtas & Kurtis", url: "/shop-grid-left-sidebar" },
        { title: "Dresses & Skirts", url: "/shop-grid-left-sidebar" },
        { title: "Jumpers & Cardigans", url: "/shop-grid-left-sidebar" },
      ],
    },
    {
      title: "Shoes & Boots",
      links: [
        {
          title: "All Shoes & Boots",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Branded Shoes",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Boots",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Heels",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Sandals",
          url: "/shop-grid-left-sidebar",
        },
        {
          label: "Shoes",
          url: "/shop-grid-left-sidebar",
        },
        {
          label: "Wide Fit Shoes",
          url: "/shop-grid-left-sidebar",
        },
      ],
    },
    {
      title: "Bags & Accessories",
      links: [
        {
          title: "All Bags & Accessories",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Accessories",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Bags & Purses",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Shoulder Bags",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Skin Care",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Hair Accessories",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Artificial Jewellery",
          url: "/shop-grid-left-sidebar",
        },
      ],
    },
    {
      title: "Collections",
      links: [
        {
          title: "All Collections",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Winter & Seasonal Wear",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Beauty & Grooming",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Ballerinas",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Party Dresses",
          url: "/shop-grid-left-sidebar",
        },
        {
          title: "Ethnic Wear",
          url: "/shop-grid-left-sidebar",
        },
      ],
    },
  ];
  const [shopLinks] = useState([
    {
      title: "Product Pages",
      links: [
        { title: "Product Left Image", url: "/product-left-image" },
        { title: "Product Right Image", url: "/product-right-image" },
      ],
    },
  ]);
  useEffect(() => {
    // Set the initial state based on the screen width
    const handleResize = () => {
      setIsOpen(window.innerWidth > 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div>
        <header className="site-header">
          <div id="header-wrap" className="shadow-sm">
            <Container className="container">
              <Row>
                {/* <!--menu start--> */}
                <Col>
                  <nav className="navbar navbar-expand-lg navbar-light position-static">
                    <Link className="navbar-brand logo d-lg-none" to="/">
                      <img
                        className="img-fluid"
                        src="/assets/images/Chamaa-Moda-Final-Logo.png"
                        alt=""
                      />
                    </Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarNav"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                      onClick={() => ToggleNavbar()}
                    >
                      <span className="navbar-toggler-icon"></span>
                    </button>
                    {
                      isOpen && (
                         <div
                      className={
                        "collapse navbar-collapse  show" 
                      }
                      id="navbarNav"
                    >
                      <ul className="navbar-nav">
                        <li className="nav-item">
                          <span  className="nav-link" onClick={()=>{ToggleNavbar();navigate("/");}}>
                            Home
                          </span>
                        </li>

                        <CatalogDropDown
                          title={"Categories"}
                          catagoryLinks={catagoryLinks}
                          ToggleNavbar={ToggleNavbar}
                        />

                        <li className="nav-item">
                          <span  className="nav-link"  onClick={()=>{ToggleNavbar();navigate("/gallery");}}>
                            Gallery
                          </span>
                        </li>
                        <li className="nav-item">
                          <span  className="nav-link"  onClick={()=>{ToggleNavbar();navigate("/about-us");}}> 
                            About us
                          </span>
                        </li>

                        <li className="nav-item">
                          <span className="nav-link" onClick={()=>{ToggleNavbar();navigate("/contact-us");}}>
                            Contact
                          </span>
                        </li>
                      </ul>
                    </div> 
                      )
                    }
                  
                    {/* <div className="right-nav align-items-center d-flex justify-content-end">
                    <Link className="login-btn btn-link ms-3" to="/login">
                      <i className="las la-user-alt"></i>
                    </Link>
                  </div> */}
                  </nav>
                </Col>
                {/* <!--menu end--> */}
              </Row>
            </Container>
          </div>
        </header>
      </div>
    </>
  );
}
