import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BackToTop from "./Components/BackToTop";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import AboutUs from "./Pages/AboutUs";
import BlogCards from "./Pages/BlogCards";
import ErrorPage from "./Pages/ErrorPage";
import Faq from "./Pages/Faq";
import Index from "./Pages/Fashion2";
import PGFW from "./Pages/PGFW";
import PGLS from "./Pages/PGLS";
import PGNS from "./Pages/PGNS";
import PGRS from "./Pages/PGRS";
import PLFW from "./Pages/PLFW";
import PLI from "./Pages/PLI";
import PLLS from "./Pages/PLLS";
import PLNS from "./Pages/PLNS";
import PLRS from "./Pages/PLRS";
import PrivacyPage from "./Pages/PrivacyPage";
import TAndC from "./Pages/tAndC";
import ContatctUs from "./Pages/contact/ContatctUs";
import { useDispatch } from "react-redux";
import { filterProducts, setProducts } from "./store/reducer/productReducer.js";
import products from "./api/products.js";
import { setSelectedBlog, updateBlogItems  } from "./store/reducer/blogReducer.js";
import blogList from "./api/blogList.js";

function App() {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dispatch=useDispatch()
  const isSpecialRoute =
    location.pathname.includes("maintenance") ||
    location.pathname.includes("coming-soon") ||
    location.pathname.includes("error-404");

  useEffect(() => {
    const handlePopstate = () => {
      window.location.reload(); // Refresh the page on popstate event (back button)
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, [location.pathname]);
  
  const [showGif, setShowGif] = useState(true);

  useEffect(() => {
         dispatch(setProducts(products))
    // Show the GIF for 3 seconds, then show the modal
    const timeout = setTimeout(() => {
 
      setShowGif(false);

    }, 1000);

    return () => clearTimeout(timeout);
  }, [showGif]);


  const scrollbarStyle = `
  ::-webkit-scrollbar {
    display: none;
  }
`;
useEffect(() => {
  dispatch(updateBlogItems(blogList));
}, [dispatch,blogList]);
  return (
    <>
      <style>{scrollbarStyle}</style>
      {showGif && (
        <div id="ht-preloader">
          <div className="loader clear-loader">
            <img className="img-fluid" src="assets/images/loader.gif" alt="" />
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {isSpecialRoute ? (
        <Routes>
          <Route path="/error-404" element={<ErrorPage />} />
        </Routes>) : (
        <div className="page-wrapper"> 
        <Header isOpen={isOpen} setIsOpen={setIsOpen} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />
        <Routes>
        <Route path="/" element={<Index isOpen={isOpen} setIsOpen={setIsOpen} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />} />
          <Route path="/home" element={<Index isOpen={isOpen} setIsOpen={setIsOpen} isDropdownOpen={isDropdownOpen} setIsDropdownOpen={setIsDropdownOpen} />} />
          <Route path="/shop-grid-left-sidebar" element={<PGLS   setShowGif={setShowGif} />} />
          <Route path="/product-left-image" element={<PLI  />} />
          <Route path="/about-us" element={<AboutUs  />} />
          <Route path="/gallery" element={<BlogCards setShowGif={setShowGif}  />} />
          <Route path="/contact-us" element={<ContatctUs  />} />
          <Route path="/*" element={<ErrorPage  />} />
        </Routes>
        <Footer />

        <BackToTop />
      </div>)}

    </>
  );
}

export default App;
