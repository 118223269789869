import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useEffect, useState } from "react";
import ReactImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import OwlCarousel from "react-owl-carousel";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Nav,
    NavItem,
    NavLink,
    Progress,
    Row,
    TabContent,
    TabPane,
    Table
} from "reactstrap";
import PageHeading from "../Components/PageHeading/PageHeading";
import ProductCard from "../Components/ProductCard";




function PLI() {
    const dispatch = useDispatch();
    const allProducts = useSelector((state) => state.products.allProducts);

    const id = useSelector((state) => state.products.selectedProduct);
    const filteredProducts = useSelector(
        (state) => state.products.filteredProducts
    );
    let product = filteredProducts.find((p) => p.id === Number(id));
    useEffect(() => {
        window.scrollTo(0,0)
    }, [product])
    let cat;
    if (product === undefined) {
        cat = "Floral Trim"
    } else {
        cat = product.category;
    }
    const firstBreadcrumb = { label: "Pages", link: "/product-left-image" };
    const secondBreadcrumb = {
        label: "Product Left Image",
        link: "/product-left-image",
        active: true,
    };
    const [quantity, setQuantity] = useState(1);
    const [activeTab, setActiveTab] = useState("1");

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [rating, setRating] = useState("");
    const [phone, setPhone] = useState("");
    const [comment, setComment] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
    };
    const starRatings = [
        { stars: 5, percentage: 90, color: "success" },
        { stars: 4, percentage: 60, color: "success" },
        { stars: 3, percentage: 40, color: "success" },
        { stars: 2, percentage: 20, color: "warning" },
        { stars: 1, percentage: 10, color: "danger" },
    ];

    const RatingBar = ({ stars, percentage, color }) => (
        <div className="d-flex align-items-center mb-2">
            <div className="text-nowrap me-3">{stars} Star</div>
            <div className="w-100">
                <Progress value={percentage} color={color} style={{ height: "5px" }} />
            </div>
            <span className="text-muted ms-3">{percentage}%</span>
        </div>
    );

    function refreshPage() {
        window.location.reload(true);
    }
    const filterProducts = () => {
        const topRatedProducts = allProducts.filter((product) => product.category === cat);
        return renderProductCards(topRatedProducts);
    };
    const renderProductCards = (filteredProducts) => {
        return filteredProducts.map((product) => (
            <div className="item" key={product.id}>
                {/* Rest of the ProductCard component code */}
                <ProductCard
                    id={product.id}
                    imgBackSrc={`assets/images/${product.pictures[0]}`}
                    imgFrontSrc={`assets/images/${product.pictures[1]}`}
                    title={product.name}
                    price={product.salePrice}
                    actualPrice={product.price}
                    rating={product.rating}
                    onClick={() => { refreshPage() }}
                />
            </div>
        ));
    };

    const [selectedColor, setSetlectedColor] = useState("");
    const handleColorClick = (color) => {
        setSetlectedColor(color);
    };
    if (product === undefined ? (product = allProducts[0]) : product)
        return (
            <div className="page-wrapper">
                <PageHeading
                    title="Product Left Image"
                    firstBreadcrumb={firstBreadcrumb}
                    secondBreadcrumb={secondBreadcrumb}
                />
                <div className="page-content">
                    <div>
                        <section>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-12">


                                        <ReactImageGallery
                                            items={product.pictures.map((image, index) => ({
                                                original: `assets/images/${image}`,
                                                thumbnail: `assets/images/${image}`,
                                            }))}
                                            showNav={true}
                                            showThumbnails={false}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                            swipe={true} // Enable swipe functionality
                                        />

                                    </div>
                                    <div className="col-lg-6 col-12 mt-5 mt-lg-0">
                                        <div className="product-details">
                                            <h3 className="mb-0">{product.name}</h3>
                                            
                                            {/* <span className="product-price h4">
                                                ${product.salePrice}
                                                <del className="text-muted h6">${product.price}</del>
                                            </span> */}
                                            <ul className="list-unstyled my-4">
                                                {/* <li className="mb-2">
                                                    Availibility: <span className="text-muted">{product.stock}</span>
                                                </li> */}
                                                <li>
                                                    Categories: <span className="text-muted">{product.category}</span>
                                                </li>
                                            </ul>
                                            <p className="mb-4">{product.description}</p>

                                            <div className="d-sm-flex align-items-center mb-5">
                                                <div className="d-sm-flex align-items-center">
                                                    {/* <div className="d-flex align-items-center mr-sm-4">
                                                        <Button
                                                            className="btn-product btn-product-up"
                                                            onClick={() => {
                                                                if (quantity > 1) setQuantity(quantity - 1);
                                                            }}
                                                        >
                                                            <i className="las la-minus"></i>
                                                        </Button>
                                                        <Input
                                                            className="form-product"
                                                            type="number"
                                                            name="form-product"
                                                            value={quantity}
                                                            onChange={(e) => {
                                                                const newQuantity = parseInt(e.target.value);
                                                                if (
                                                                    newQuantity >= 1 &&
                                                                    newQuantity <= product.stock
                                                                ) {
                                                                    setQuantity(newQuantity);
                                                                }
                                                            }}
                                                            max={product.stock}
                                                        />
                                                        <Button
                                                            className="btn-product btn-product-down"
                                                            onClick={() => {
                                                                if (quantity < product.stock)
                                                                    setQuantity(quantity + 1);
                                                            }}
                                                        >
                                                            <i className="las la-plus"></i>
                                                        </Button>
                                                    </div> */}
                                                </div>
                                                <div className="d-flex  ml-sm-4 mt-3 mt-sm-0">
                                                    {product.colors.map((col, index) => (
                                                        <div className="form-check pl-2">

                                                            <div className="form-check pl-0">
                                                                <input type="checkbox" value={col} onChange={() => handleColorClick(col)} className="form-check-input" id={col} />
                                                                <label className="form-check-label" style={{ background: col }} htmlFor={col} />
                                                            </div>
                                                            {/* <small>{color}</small> */}
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>


                                            <div className="product-link d-flex align-items-center mt-4">
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={
                                                    activeTab === "1" ? "text-dark active" : "text-dark"
                                                }
                                                onClick={() => {
                                                    toggle("1");
                                                }}
                                            >
                                                Additional Information
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab} className="pt-5">
                                        <TabPane tabId="1">
                                            <h5 className="mb-3">Additional information</h5>
                                            <Table bordered className="mb-0">
                                                <tbody>
                                                    
                                                    <tr>
                                                        <td>Color</td>
                                                        <td>{product.colors.join(" ")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Chest</td>
                                                        <td>38 inches</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Waist</td>
                                                        <td>20 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Length</td>
                                                        <td>35 cm</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Fabric</td>
                                                        <td>Cotton, Silk &amp; Synthetic</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Warranty</td>
                                                        <td>6 Months</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </Container>
                        <section>
                            <Container>
                                <div className="row justify-content-center text-center">
                                    <div className="col-lg-8 col-md-10">
                                        <div className="mb-5">
                                            <h6 className="text-primary mb-1">
                                                — You may also like
                                            </h6>
                                            <h2 className="mb-0">Related Products</h2>
                                        </div>
                                    </div>
                                </div>
                                <Row>
                                    <Col>
                                        <OwlCarousel
                                            className="owl-carousel no-pb owl-2"
                                            dots={false}
                                            nav={true}
                                            items={3}
                                            responsive={{
                                                0: { items: 1 },
                                                576: { items: 2 },
                                                768: { items: 2 },
                                                992: { items: 3 },
                                            }}
                                            navText={["<span class='las la-arrow-left'><span></span></span>","<span class='las la-arrow-right'><span></span></span>"]}
                                        >
                                            {filterProducts()}
                                        </OwlCarousel>
                                    </Col>
                                </Row>
                            </Container>
                        </section>
                    </div>
                </div>
            </div>
        );
}

export default PLI;
