import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import BlogSection from "../Components/Blog/BlogSection";
import InstagramSection from "../Components/InstagramSection/InstagramSection";
import LogoSection from "../Components/LogoSection/LogoSection";
import NewsletterSection from "../Components/News/NewsLetterSection";
import PageHeading from "../Components/PageHeading/PageHeading";
import { updateBlogItems } from "../store/reducer/blogReducer";
import blogList from "../api/blogList";

function AboutUs() {
  const firstBreadcrumb = { label: "Pages" };
  const secondBreadcrumb = {
    label: "About Us",
    active: true,
  };
  const dispatch = useDispatch();
  const logos = [
    "assets/images/client/01.png",
    "assets/images/client/02.png",
    "assets/images/client/03.png",
    "assets/images/client/04.png",
    "assets/images/client/05.png",
    "assets/images/client/06.png",
    "assets/images/client/07.png",
    "assets/images/client/08.png",
  ];

  useEffect(() => {
    dispatch(updateBlogItems(blogList));
  }, [dispatch, blogList]);
  const blogs = useSelector((state) => state.blog.blogItems);
  const filteredBlogs = blogs
    .filter(
      (blog) =>
        blog.category.includes("Crochet Trim") ||
        blog.category.includes("Knitted Metallic Net")
    )
    .slice(0, 3);
  const reasonsData = [
    {
      icon: <i className="las la-shipping-fast ic-3x text-primary"></i>,
      title: "Free Shipping",
      description: "Enjoy free shipping on all orders!",
    },

    {
      icon: <i className="las la-undo-alt ic-3x text-primary"></i>,
      title: "Money Return",
      description: "30-day hassle free returns - satisfaction guaranteed.",
    },
    {
      icon: <i className="las la-credit-card ic-3x text-primary"></i>,
      title: "Secure Payment",
      description: "Seamless transactions with our secure Credit Card.",
    },

    {
      icon: <i className="las la-history ic-3x text-primary"></i>,
      title: "24/7 Support",
      description: "24/7 support, always here for you!",
    },
  ];

  return (
    <div className="page-wrapper">
      <PageHeading
        title="About Us"
        firstBreadcrumb={firstBreadcrumb}
        secondBreadcrumb={secondBreadcrumb}
      />
      <div className="page-content">
        <section className="p-0">
          <Container>
            <div className="row align-items-center justify-content-between">
              <Col lg="7" mb="6" mbLg="0">
                <Row className="align-items-center">
                  <Col md="6" className="d-none d-md-block">
                    <img
                      src="/assets/images/product/main/ChamaaModaProduct/036A3557.JPG"
                      className="img-fluid rounded shadow"
                      alt="..."
                    />
                  </Col>
                  <Col md="6">
                    <img
                      src="assets/images/about/bg-1.jpg"
                      className="img-fluid rounded shadow mb-5"
                      alt="..."
                    />
                    <img
                      src="assets/images/about/bg-3.jpg"
                      className="img-fluid rounded shadow d-none d-md-block"
                      alt="..."
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg="5">
                <div>
                  <h6 className="text-primary mb-1">— About Us</h6>
                  <h2 className="mt-3 font-w-5">
                    We Have New Talents Experience
                  </h2>
                  <p className="mb-0">
                    Founded in 1930 by Abed Al Latif Chamaa, Chamaa Moda is a
                    legacy in the fashion accessories industry. Originally
                    located in downtown Lebanon, the brand established itself as
                    a pioneer in delivering high-quality fashion accessories.
                    Over the years, Chamaa Moda has evolved into a leading
                    supplier, catering specifically to the discerning needs of
                    ready garment factories.
                  </p>
                </div>
              </Col>
            </div>
            <Row className="mt-8">
              <Col lg="12" md="12">
                {/* <h5>
                  <span className="text-primary font-w-7">01.</span> Our Mission
                </h5> */}
                <p>
                  With its roots deeply embedded in the heart of Lebanon, Chamaa
                  Moda's headquarters now reside on Kaskas Street in the vibrant
                  city of Beirut. The brand's commitment to excellence and
                  innovation has made it a trusted partner for businesses
                  seeking premium accessories to enhance and complement their
                  garment production.
                </p>
              </Col>
              <Col lg="12" md="12">
                {/* <h5>
                  <span className="text-primary font-w-7">01.</span> Our Mission
                </h5> */}
                <p>
                  Expanding its reach beyond borders, Chamaa Moda proudly
                  operates a branch in Syria, further solidifying its presence
                  in the region. The brand's influence extends to the United
                  Arab Emirates (UAE), where it distributes its high-quality
                  fashion accessories to meet the growing demands of the dynamic
                  fashion industry.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row className="align-items-end mb-5">
              <Col lg="6">
                <div>
                  <img
                    src="assets/images/blog/bg-4.jpg"
                    className="img-fluid rounded shadow"
                    alt="..."
                  />
                </div>
              </Col>
              <Col lg="6">
                <div>
                  <h2 className="text-primary mb-1">— Mission</h2>
                  <p className="lead mb-0">
                    At Chamaa Moda, our legacy is rooted in a commitment to
                    empower the fashion industry. We provide top-notch
                    accessories that not only elevate the appeal and quality of
                    ready garments but also reflect a tradition of excellence
                    established by Abed Al Latif Chamaa himself. We strive to be
                    the go-to source for fashion-forward and reliable
                    accessories, contributing to the success of our clients
                    globally.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="align-items-center">
              {reasonsData.map((reason, index) => (
                <Col lg="3" md="6" key={index}>
                  <div className="px-4 py-7 rounded text-center border">
                    {reason.icon}
                    <h5 className="mt-2 mb-1">{reason.title}</h5>
                    <p className="mb-0">{reason.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <NewsletterSection />
        {/* <LogoSection logos={logos} /> */}
        <BlogSection blogs={filteredBlogs} title={""} />
        <InstagramSection />
      </div>
    </div>
  );
}

export default AboutUs;
