import { createSlice } from "@reduxjs/toolkit";
import products from "../../api/products";

const productsSlice = createSlice({
  name: "products",
  initialState: {
    allProducts: products,
    filteredProducts: [],
    selectedProduct: "",
    filters: { category: [], colors: "", prices: [0, 10000] },
  },
  reducers: {
    setProducts(state, action) {
      state.allProducts = action.payload;
      state.filteredProducts = action.payload;
    },
    setSelectedProduct(state, action) {
      state.selectedProduct = action.payload;
    },
    setSelectedPortFolio(state, action) {
      state.selectedPortFolio = action.payload;
    },
    setFilters(state, action) {
      state.filters = { ...state.filters, ...action.payload };
    },

    filterProducts(state) {
      const { allProducts, filters } = state;
      const { category, colors,  prices } = filters;

      const [minPrice, maxPrice] = prices;

      const filteredProducts = allProducts.filter((product) => {
        const isCategoryMatch =
          category.length === 0 ||
          category.some(
            (selectedCategory) =>
              selectedCategory.toLowerCase() === product.category.toLowerCase()
          );

        const isColorMatch =
          !colors ||
          product.colors.some(
            (color) => color.toLowerCase() === colors.toLowerCase()
          );


        const isPriceInRange =
          product.salePrice >= minPrice && product.salePrice <= maxPrice;
        return isCategoryMatch && isColorMatch &&  isPriceInRange;
      });

      state.filteredProducts = filteredProducts;
    },

    resetFilters(state) {
      state.filters = {
        category: [],
        colors: "",
        prices: [0, 10000],
      };
      state.filteredProducts = state.allProducts;
    },
  },
});

export const {
  setProducts,
  setFilters,
  filterProducts,
  resetFilters,
  setSelectedProduct,
} = productsSlice.actions;

export default productsSlice.reducer;
