import { createSlice } from "@reduxjs/toolkit";
import blogList from "../../api/blogList";
const blogSlice = createSlice({
  name: "blog",
  initialState: {
    blogItems: [],
    selectedBlog: "",
  },
  reducers: {
    setSelectedBlog(state, action) {
      state.selectedBlog = action.payload;
    },
    updateBlogItems(state, action) {
      // Assuming action.payload is an array of updated blog items
      state.blogItems = [];

      // Assuming action.payload is an array of updated blog items
      state.blogItems = action.payload;
    },
  },
});

export const { setSelectedBlog , updateBlogItems  } = blogSlice.actions;

export default blogSlice.reducer;
